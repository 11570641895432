import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import { Router } from '@angular/router';
import { ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService,
    private router: Router,
    private toastService: ToastService
  ) { }

  /**
   * @desc Login route
   * @param params
   */
  login(params) { return this.apiService.doRequest( 'api/login/token' , 'POST', params, true); }

  /**
   * @desc Validate token route
   */
  validateToken(params){ return this.apiService.doRequest('api/login/validate', 'POST', params, false); }

  /**
   * @desc Update user information
   * @param params
   */
  updateUserDetails(params) { return this.apiService.doRequest('api/boarding' , 'POST', params); }

  /**
   * @desc Update user password
   * @param params
   */
  changePassword(params) { return this.apiService.doRequest('api/password/change' , 'POST', params); }

  /**
   * @desc Register User
   * @param params
   */
  registerUser(params) { return this.apiService.doRequest('api/account/register' , 'POST', params); }

  /**
   * @desc Send Token for Reset Password - Used in Forgot Password Page
   * @param params
   */
  sendResetPasswordToken(params) { return this.apiService.doRequest('api/password/reset?' , 'GET', params); }

  /**
   * @desc Validate Token and change password - Used in Forgot Password Page
   * @param params
   */
  resetPassword(params) { return this.apiService.doRequest('api/password/reset' , 'POST', params); }


  // Get User Data
  get() { return this.apiService.doRequest('api/myData' , 'GET', '', false); }
}
