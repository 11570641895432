import { Component } from '@angular/core';
import {Storage} from "@ionic/storage";
import { SplashScreen } from '@capacitor/splash-screen';
import {NavController, Platform} from "@ionic/angular";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private navCtrl : NavController,
    private storage: Storage,
  ) {
    this.initializeApp();
  }

  async initializeApp(){

    this.platform.ready().then(() => {
      SplashScreen.show({
        showDuration: 2000,
        autoHide: true
      }).then(res => {
        this.storage.get('onboarding')
          .then(value => {
            if (value === null) {
              this.navCtrl.navigateRoot(['/onboarding']);
            }
          });
      });

    });


  }
  async ngOnInit(){
    await this.storage.create();
  }

  logout() {
    localStorage.clear();
    this.navCtrl.navigateRoot(['/']);
  }
}
