import axios from 'axios';
const instance = axios.create();
export default instance;

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastService} from './toast.service';
import {Router} from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl = 'https://depozit.stagingserver.live/';
  // apiUrl = 'http://59fbb5c6fec5.ngrok.io/';
  loggedIn = false;
  token = '';
  httpResponse : any;
  public static ola : any[];
  constructor(
    private http: HttpClient,
    public toastService: ToastService,
    private router: Router
  ) {}

  doRequest(url, method, data,ignoreAuth = false ) {
    let apiSrvc = this;
    let resp;

    //Serialize Params for GET Method
    if(method === 'GET') {
      if (url[url.length - 1] === '?') {
        let _get_params = this.setUrlParams(data);
        url = url + _get_params;
      } else {
        url = url + data;
      }
    }

    //Insert Token
    let token = localStorage.getItem('token');

    const Hoptions = {
      headers: {
        'Content-Type':  'application/json',
        'Accept'  : 'application/json'
      }
    };

    const HoptionsLogged = {
      headers: {
        'Content-Type':  'application/json',
        'Accept'  : 'application/json',
        'Authorization' : 'Bearer' + token
      }
    };


    switch (method) {
      case 'POST':
      case 'PATCH':
      case 'PUT':
        resp = instance[method.toLowerCase()](this.apiUrl + url,data, (token === null) ? Hoptions : HoptionsLogged);
        break;
      case 'GET':
        resp =  instance[method.toLowerCase()](this.apiUrl + url, (token === null) ? Hoptions : HoptionsLogged);
        break;
    }

    return resp;
  }

  setToken(token) { this.token = token; }
  setLogin(loggedIn) { this.loggedIn = loggedIn;}
  setUrlParams = function (params) {
    let _params = [];
    for (var prop in params) { _params.push(encodeURIComponent(prop) + '=' + encodeURIComponent(params[prop])); }
    return _params.join("&");
  };

}
