import {Injectable} from '@angular/core';
import {NavController, ToastController} from '@ionic/angular';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(
        public toastController: ToastController,
        private router: Router,
        private navController: NavController
    ) {
    }

    async toast(message, status = 'error', redirect = null, refresh = false) {
        const toast = await this.toastController.create({
            message,
            color: status === 'error' ? 'danger' : 'success',
            duration: status === 'error' ? 8000 : 4000
        });
        await toast.present();

        if (redirect !== null) {
            (refresh !== false) ? this.navController.navigateRoot([redirect], {animated: true}) : this.router.navigateByUrl(redirect);
        }
    }

    validateForm(currentForm) {
        if (currentForm.invalid) {
            for (const control in currentForm.controls) {
                if (currentForm.controls[control].hasOwnProperty('touched')) {
                    currentForm.controls[control].touched = true;
                }
            }

            this.toast('Complete all fields correctly!');
            return true;
        }
        return false;
    }

}



